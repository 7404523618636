import MediaQuery from '@ui/MediaQuery';
import clsx from 'clsx';
import { memo } from 'react';
import StaticIcon from '@ui/StaticIcon';
import Image from '@ui/Image';
import Typography from '@ui/Typography';
import {
  FeedbackMailLink, FeedbackPhoneLink, useContactsQuery,
} from '@entities/company';
import { t } from '@lib/i18n';
import { InfoColumn } from '.';

const SalesManLinkedinLink = ({ link, className }) => {
  return (
    <div className={clsx(
      className,
    )}
    >
      <a
        href={link}
        target="_blank"
      >
        <StaticIcon name="linkedin" className="h-[20px] w-[20px] sm:h-[25px] sm:w-[25px]" />
      </a>
    </div>
  );
};

const SalesManCard = ({
  person: {
    avatar, name, role, alt_image, image_text, qr, company, text, linkedin,
  },
}) => {
  const { address } = useContactsQuery();

  return (
    <div className="flex flex-col sm:text-center lg:w-[400px] xl:w-[528px]">
      <div className="flex justify-between sm:justify-center">
        <div>
          <div className="md-up:flex md-up:space-x-[22px]">
            <div className="relative">
              <Image src={avatar} alt={alt_image} />
              <SalesManLinkedinLink
                link={linkedin}
                className="absolute bottom-[-12px] left-[-6px] rounded-full bg-blue-5 p-[5px] sm:hidden"
              />
            </div>
            <InfoColumn className="sm:mt-[18px] lg-down:space-y-1">
              <Typography variant="header4" className="font-medium md-up:mt-[4px]">
                {name}
              </Typography>
              <Typography>
                {role}
              </Typography>
            </InfoColumn>
          </div>
          <div className="sm:mt-4 sm:space-y-4 md-up:mt-[34px] md-up:flex md-up:space-x-4">
            <InfoColumn>
              <Typography variant="caption2" className="uppercase text-deep-blue-5">
                {t('common.email')}
              </Typography>
              <div className="flex lg-down:justify-center">
                <FeedbackMailLink variant="sales" className="flex items-center">
                  <StaticIcon name="arrow-up" className="ml-0.5" />
                </FeedbackMailLink>
              </div>
              <SalesManLinkedinLink
                link={linkedin}
                className="!mt-3 !mb-4 bg-white md-up:hidden"
              />
            </InfoColumn>
          </div>
        </div>
        {/* <MediaQuery greaterThanOrEqual="md"> */}
        {/*   <div className="flex items-end"> */}
        {/*     <div className="lg:hidden xl:block"> */}
        {/*       <StaticIcon name={image_text} /> */}
        {/*     </div> */}
        {/*     <div className="ml-3"> */}
        {/*       <StaticIcon name={qr} className="lg-only:h-[155px] lg-only:w-[155px]" /> */}
        {/*     </div> */}
        {/*   </div> */}
        {/* </MediaQuery> */}
      </div>
      <div className="h-[1px] w-full space-x-2 bg-blue-3 sm:mt-3 md-up:my-[20px] " />
      <div className="flex items-center lg:flex-col lg:items-start xl:flex-row xl:justify-between">
        <Typography className="mr-1 whitespace-nowrap sm:mx-auto sm:mt-[20px]" variant="body2">
          {company}
        </Typography>
        <MediaQuery greaterThanOrEqual="md">
          <div className="flex items-center whitespace-nowrap sm:flex-col sm:space-y-2 md-up:ml-auto md-up:space-x-1 lg:ml-0">
            {text.map((item, i) => (
              <>
                <Typography variant="body2">
                  {item}
                </Typography>
                {(i !== text.length - 1) && <div className="h-[2px] w-[2px] rounded-full bg-blue-2 sm:hidden" />}
              </>
            ))}
          </div>
        </MediaQuery>
      </div>
    </div>
  );
};

export default memo(SalesManCard);
