import { FieldBuilder } from '@ui/FieldBuilder';
import { CONTACTS_PAGE } from '@assets/content/data';
import { RequestFeedbackForm } from '@features/request-feedback';
import { t } from '@lib/i18n';

import MediaQuery from '@ui/MediaQuery';
import Typography from '@ui/Typography';
import { FeatureLayout } from '@widgets/layout';
import { useForm } from 'react-hook-form';
import { ClientLogos } from '@widgets/client-logos';
import { SalesManCard, FormFooter } from './ui';

const tr = (key) => t(`contacts.${key}`);

const FeatureContent = () => (
  <>
    <Typography variant="header1" center>
      {tr('header')}
    </Typography>
    <Typography variant="subhead1" center className="!mt-4">
      {tr('subheader')}
    </Typography>
  </>
);

const ContactsPage = () => {
  const requestCallForm = useForm({ mode: 'onChange' });

  return (
    <FeatureLayout
      mainLayoutProps={{ seo: CONTACTS_PAGE.en.seo }}
      featureChildren={<FeatureContent />}
    >
      <MediaQuery lessThan="lg">
        <div>
          <div className="grid-layout mx-auto">
            <div className="flex lg:mt-9 lg-down:flex-col">
              <div className="xl:col-6 lg:col-5 sm:pt-7 md:pt-8 lg-down:pb-[21px]">
                <SalesManCard person={tr('sales_man')} />
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
      <div className="grid-layout mx-auto mb-12 sm:mb-7 md:mb-8">
        <div className="flex lg:mt-9 lg-down:flex-col">
          <MediaQuery greaterThan="md">
            <div className="lg:col-5 sm:pt-7 md:pt-8 lg-down:pb-[21px]">
              <SalesManCard person={tr('sales_man')} />
            </div>
          </MediaQuery>
          <div className="lg:col-6 sm:mt-7 md:mt-8 lg:ml-auto">
            <RequestFeedbackForm
              markRequiredFields={false}
              submitButtonClassName="w-[146px]"
              form={requestCallForm}
              footer={<FormFooter />}
              // extra={(
              //   <FieldBuilder.GoogleCaptchaV2
              //     name="captcha"
              //   />
              // )}
            />
          </div>
        </div>
      </div>
      <section className="py-12 sm:py-7 md:py-8">
        <Typography
          variant="header2"
          center
          className=""
        >
          {t('main_page.feedback.title')}
        </Typography>
        <ClientLogos className="mt-12 sm:mt-7 md:mt-8" />
      </section>
    </FeatureLayout>
  );
};

export default ContactsPage;
